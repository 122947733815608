import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Julio Rocher
        </h1>
        <p>Website under construction...</p>
      </header>
    </div>
  );
}

export default App;
